import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import instance from '../../config/axios';
import NavBar from '../../components/NavBar';

const ShowUser = () => {
  const [user, setUser] = useState({});

  let { company_id, id } = useParams();

  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users/show/${id}`,
    })
      .then((response) => {
        let refined_response = response.data;

        if (response.data.account_statements.length > 0) {
          refined_response.account_statements.forEach((ac_st, index) => {
            refined_response.account_statements[index].amount =
              ac_st.amount.$numberDecimal;
          });
        }
        setUser(refined_response);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const render_user = () => {
    let users_html = (
      <>
        <tr>
          <td>{user.account_pk}</td>
          <td>{user.username}</td>
          <td>{user.notes}</td>
          <td>
            {new Intl.NumberFormat().format(user.balance?.$numberDecimal)}
          </td>
          <td>{user.company_name}</td>
        </tr>
      </>
    );

    return users_html;
  };

  const render_account_statement = () => {
    let account_statement_html = [];

    if (user.account_statements?.length) {
      user.account_statements.forEach((single_account_statement) => {
        account_statement_html.push(
          <>
            <tr>
              <td>{single_account_statement._id}</td>
              <td>
                {new Intl.NumberFormat().format(
                  single_account_statement.amount
                )}
              </td>
            </tr>
          </>
        );
      });
    } else {
      account_statement_html.push(
        <>
          <tr>
            <td colspan='5'>(No Account Statements Found)</td>
          </tr>
        </>
      );
    }

    return account_statement_html;
  };

  return (
    <>
      <NavBar show_navs={true} />
      <div className='container'>
        <table className='table shadow mt-2'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>ID</th>
              <th scope='col'>Username</th>
              <th scope='col'>Notes</th>
              <th scope='col'>Balance</th>
              <th scope='col'>Company</th>
            </tr>
          </thead>
          <tbody>{render_user()}</tbody>
        </table>

        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>Acc St. ID</th>
              <th scope='col'>Amount</th>
            </tr>
          </thead>

          <tbody>{render_account_statement()}</tbody>
        </table>
      </div>
    </>
  );
};

export default ShowUser;
