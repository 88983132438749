import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import NavBar from '../../components/NavBar';
import { toast } from 'react-toastify';

const Transactions = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [toggle_columns, setToggleColumns] = useState(false);
  const [toggle_add_btn, setToggleAddBtn] = useState(false);
  const [date_input, setDateInput] = useState(new Date().toISOString().split('T')[0]);
  const [show_notes_modal, setNotesModal] = useState(false);
  const [edit_transaction_id, setEditTransaction] = useState(null);
  const [permissions, setPermissions] = useState({});

  let { company_id } = useParams();
  let history = useHistory();

  // Fetch all Transactions on page load
  useEffect(() => {
    let current_date = new Date().toISOString().split('T')[0];
    localStorage.setItem('transaction_date', current_date);
    fetch_transactions(current_date);
  }, []);

  useEffect(() => {
    let current_date = new Date().toISOString().split('T')[0];

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      if (date_input < current_date) {
        // old date is selected currently, so just hide the btn. Don't show it
        setToggleAddBtn(false);
      } else {
        setToggleAddBtn(true);
      }
    } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length > 0) {
      if (date_input < current_date) {
        // old date is selected currently, so just hide the btn. Don't show it
        setToggleAddBtn(false);
      } else {
        // either current date is selected or future date is selected
        // so show/hide button based on permissions
        setToggleAddBtn(permissions.transactions.add);
      }
    }
  }, [permissions]);

  const fetch_transactions = (transaction_date) => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/transactions?transaction_date=${transaction_date}`,
    })
      .then((response) => {
        let refined_response = response.data.transactions;
        refined_response.forEach((single_transaction, index) => {
          refined_response[index].amount = single_transaction.amount.$numberDecimal;
        });

        localStorage.setItem('new_transaction_id', refined_response.length + 1);
        setTransactions(refined_response);
        setPermissions(response.data.permissions);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
      });
  };

  const render_transactions = () => {
    let transactions_html = [];
    transactions.forEach((single_transaction, index) => {
      transactions_html.push(
        <>
          <tr>
            <td className='text-center'>{index + 1}</td>
            <td className='text-center'>{single_transaction.sender_username}</td>
            {toggle_columns ? <td className='text-center'>{single_transaction.sender_sub_account}</td> : ''}
            <td className={parseFloat(single_transaction.amount) > 0 ? 'text-center plus-color' : 'text-center neg-color'}>
              {new Intl.NumberFormat().format(parseInt(single_transaction.amount))}
            </td>
            <td className='text-center'>{single_transaction.receiver_username}</td>
            {toggle_columns ? <td className='text-center'>{single_transaction.receiver_sub_account}</td> : ''}
            <td>{single_transaction.narration}</td>
            <td>{single_transaction.notes}</td>
            {render_edit_btn(single_transaction)}
          </tr>
        </>
      );
    });
    return transactions_html;
  };

  const render_edit_btn = (single_transaction) => {
    let edit_btn_html = <td></td>;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      edit_btn_html = (
        <td>
          <button className='btn btn-sm btn-primary ml-1' onClick={handleShow} data-id={single_transaction._id}>
            Edit
          </button>
        </td>
      );
    } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length > 0 && permissions.transactions.edit === true) {
      edit_btn_html = (
        <td>
          <button className='btn btn-sm btn-primary ml-1' onClick={handleShow} data-id={single_transaction._id}>
            Edit
          </button>
        </td>
      );
    }

    return edit_btn_html;
  };

  const handleClose = () => {
    setNotesModal(false);
  };

  const handleShow = (event) => {
    setEditTransaction(event.target.dataset.id);
    setNotesModal(true);
  };

  const render_sub_accounts_toggle = () => {
    let sub_account_toggle_html = (
      <div className='form-check d-flex align-items-center'>
        <input
          type='checkbox'
          className='form-check-input'
          id='toggle_columns'
          defaultChecked={toggle_columns}
          onChange={() => {
            setToggleColumns(!toggle_columns);
          }}
        />
        <label className='form-check-label' htmlFor='toggle_columns'>
          Show Sub-Accounts
        </label>
      </div>
    );

    return sub_account_toggle_html;
  };

  const setDatePlaceholder = () => {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        // alert('1'); // Chrome
        return '(mm/dd/yyyy)';
      } else {
        // alert('2'); // Safari
        return '(yyyy-mm-dd)';
      }
    }
  };

  const render_date_filter = () => {
    let current_date = new Date().toISOString().split('T')[0];

    let filters_form = (
      <>
        <div className='form-group mb-0'>
          <input className='form-control' type='date' name='trip-start' defaultValue={current_date} placeholder='yyyy-mm-dd' onChange={handleDate} />
          <span
            style={{
              position: 'absolute',
              fontSize: '12px',
            }}
          >
            {setDatePlaceholder()}
          </span>
        </div>
      </>
    );

    return filters_form;
  };

  const handleDate = (event) => {
    let current_date = new Date().toISOString().split('T')[0];
    localStorage.setItem('transaction_date', event.target.value);

    if (current_date > event.target.value) {
      // User has selected an old date
      setToggleAddBtn(false);
      setDateInput(event.target.value);
    } else {
      // User has selected today's date or future date
      if (Object.keys(permissions).length > 0 && permissions.transactions.add === true) {
        setToggleAddBtn(true);
      }

      setDateInput(event.target.value);
    }

    fetch_transactions(event.target.value);
  };

  const render_add_transaction_btn = () => {
    let btn_html = <></>;

    if (toggle_add_btn) {
      btn_html = (
        <Link to={`/companies/${company_id}/transactions/new`} className='btn btn-primary'>
          Add
        </Link>
      );
    }
    return btn_html;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'put',
      url: `/companies/${company_id}/transactions/${edit_transaction_id}`,
      data: formData,
    })
      .then((response) => {
        setNotesModal(false);
        fetch_transactions(date_input);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const render_notes_modal = () => {
    let notes_modal = (
      <Modal show={show_notes_modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Notes</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className='form-group'>
              <label>Notes</label>
              <input type='text' className='form-control' placeholder='Notes' name='transaction[notes]' />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='btn btn-secondary' onClick={handleClose}>
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return notes_modal;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      {render_notes_modal()}
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-3 d-flex'>{render_sub_accounts_toggle()}</div>
          <div className='col-6 text-center'>
            <h3>Transactions</h3>
          </div>
          <div className={'col-3 d-flex align-items-center ' + (toggle_add_btn ? 'justify-content-between' : 'justify-content-end')}>
            {render_add_transaction_btn()}
            {render_date_filter()}
          </div>
        </div>

        <table className='table shadow table-striped'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col' className='text-center'>
                #
              </th>
              <th scope='col' className='text-center'>
                Plus
              </th>
              {toggle_columns ? (
                <th scope='col' className='text-center'>
                  Sub Account
                </th>
              ) : (
                ''
              )}
              <th scope='col' className='text-center'>
                Amount
              </th>
              <th scope='col' className='text-center'>
                Minus
              </th>
              {toggle_columns ? (
                <th scope='col' className='text-center'>
                  Sub Account
                </th>
              ) : (
                ''
              )}
              <th scope='col'>Narration</th>
              <th scope='col'>Notes</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>{render_transactions()}</tbody>
        </table>
      </div>
    </>
  );
};

export default Transactions;
