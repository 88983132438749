import React from 'react';
import { useHistory } from 'react-router-dom';
import instance from '../../config/axios';
import NavBar from '../../components/NavBar';

function NewCurrency() {
  let history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: '/currencies',
      data: formData,
    })
      .then((response) => {
        history.push('/currencies');
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const new_currency_form = () => {
    let currency_form = (
      <div className='container'>
        <div className='col-4'>
          <form className='mt-4' onSubmit={onSubmit}>
            <div className='form-group'>
              <input
                className='form-control'
                type='text'
                defaultValue=''
                name='currency[currency_code]'
                placeholder='Currency Code'
              />
            </div>

            <input
              type='submit'
              className='btn btn-primary'
              value='Add Currency'
            />
          </form>
        </div>
      </div>
    );

    return currency_form;
  };

  return (
    <>
      <NavBar show_navs={false} /> {new_currency_form()}
    </>
  );
}

export default NewCurrency;
