import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import instance from '../../config/axios';
import NavBar from '../../components/NavBar';
import Select from 'react-select';
import { toast } from 'react-toastify';

function ImportData() {
  let history = useHistory();
  let { company_id } = useParams();
  const [account_types, setAccountTypes] = useState([]);
  const [success_message, setSuccessMsg] = useState(false);
  const [sheet_data, setSheetData] = useState([]);
  const [sort_column, setSortColumn] = useState('');
  const [permissions, setPermissions] = useState({});
  const [sort_order, setSortOrder] = useState('asc');

  const render_success_msg = () => {
    let success_msg_html = <></>;

    if (success_message === true) {
      success_msg_html = (
        <div className='col-12 mt-4'>
          <div className='alert alert-success' role='alert'>
            Accounts Imported Successfully
          </div>
        </div>
      );
    }

    return success_msg_html;
  };

  useEffect(() => {
    if (sort_order === 'asc') {
      setSortOrder('desc');
    } else if (sort_order === 'desc') {
      setSortOrder('asc');
    }
  }, [sheet_data]);

  const render_single_row = (single_row) => {
    let row_background = '';

    if (single_row.is_found) {
      row_background = '#fff';
    } else {
      row_background = '#d79b9b';
    }

    let sheetDataHtml = (
      <tr
        style={{
          background: row_background,
        }}
      >
        <td>{single_row.username}</td>
        <td>{single_row.take}</td>
        <td>{single_row.give}</td>
      </tr>
    );

    return sheetDataHtml;
  };

  const render_all_accounts = () => {
    let all_rows_html = [];
    sheet_data.forEach((single_row) => {
      all_rows_html.push(render_single_row(single_row));
    });

    return all_rows_html;
  };

  const handleColumnClick = (event) => {
    event.preventDefault();
    let sheet_data_temp = [];

    if (sort_order === 'asc') {
      sheet_data_temp = sheet_data.sort(function (first_item, second_item) {
        if (first_item.username < second_item.username) {
          return -1;
        } else {
          return 1;
        }
      });
      setSortOrder('desc');
    } else if (sort_order === 'desc') {
      sheet_data_temp = sheet_data.sort(function (first_item, second_item) {
        if (first_item.username < second_item.username) {
          return 1;
        } else {
          return -1;
        }
      });
      setSortOrder('asc');
    }
  };

  const render_sheet_table = () => {
    let sheetDataHtml = [];

    if (sheet_data.length) {
      sheetDataHtml = (
        <div className='container mt-4'>
          <table className='table'>
            <thead>
              <tr>
                <th
                  scope='col'
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={handleColumnClick}
                >
                  <span>⇅</span>
                  <span>Username</span>
                </th>
                <th scope='col'>Take</th>
                <th scope='col'>Give</th>
              </tr>
            </thead>
            <tbody>{render_all_accounts()}</tbody>
          </table>
        </div>
      );
    }

    return sheetDataHtml;
  };

  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users/dummy_user_id/accessibility`,
    })
      .then((response) => {
        setPermissions(response.data.accessibility);

        if (Object.keys(permissions).length && response.data.accessibility.import.add === false) {
          history.push('/');
        }
      })
      .catch((error) => {});

    instance({
      method: 'get',
      url: `/companies/${company_id}/account_types?is_default=false`,
    })
      .then((response) => {
        setAccountTypes(response.data.account_types);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
        console.log(error);
      });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);
    let api_url = '';

    let valid_form = event.nativeEvent.submitter.classList.contains('btn-validate');

    if (valid_form) {
      api_url = `/companies/${company_id}/import_data/read_csv`;
    } else {
      api_url = `/companies/${company_id}/import_data`;
    }

    instance({
      method: 'post',
      url: api_url,
      data: formData,
    })
      .then((response) => {
        if (valid_form) {
          setSheetData(response.data.sheetJson);
        } else {
          setSuccessMsg(true);
          window.location.href = `/companies/${company_id}`;
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
        console.log(error.response.data.message);
      });
  };

  const get_account_types_json = () => {
    let account_types_json = [];
    account_types?.forEach((single_account) => {
      account_types_json.push({
        label: single_account.name,
        value: single_account._id,
        key: single_account._id,
      });
    });

    return account_types_json;
  };

  const new_import_data_form = () => {
    let import_form = <></>;
    let show_page = false;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      show_page = true;
    } else if (
      localStorage.getItem('eFinanceUser') === 'Company' &&
      Object.keys(permissions).length > 0 &&
      permissions.import.add === true
    ) {
      show_page = true;
    }

    if (show_page) {
      const options = get_account_types_json();

      import_form = (
        <div className='container'>
          <form onSubmit={onSubmit}>
            <div className='row mt-2'>{render_success_msg()}</div>
            <div className='row mt-4'>
              <div className='col-4'>
                <div className='form-group'>
                  <Select options={options} name='account_type' defaultValue={options[0]} />
                </div>
              </div>

              <div className='col-4'>
                <div className='form-group'>
                  <input className='form-control-file' type='file' name='import_data' />
                </div>
              </div>
            </div>

            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
            <button type='submit' className='btn btn-primary ml-2 btn-validate'>
              Validate
            </button>
          </form>
        </div>
      );
    }

    return import_form;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      {new_import_data_form()}
      {render_sheet_table()}
    </>
  );
}

export default ImportData;
