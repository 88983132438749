import React from 'react';
import { Link, useParams } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import AccessCheckbox from './access_checkbox';
import NavBar from '../../components/NavBar';

const AccountsPermissions = (props) => {
  const [accesses, setAccesses] = useState({});
  const [accounts, setAccounts] = useState([]);
  const { company_id, user_id } = useParams();

  useEffect(() => {
    // Fetch all acl rules
    instance({
      method: 'get',
      url: `/companies/${company_id}/users/${user_id}/accessibility`,
    })
      .then((response) => {
        setAccesses(response.data.accessibility);
      })
      .catch((error) => {});

    // Fetch all accounts
    instance({
      method: 'get',
      url: `/companies/${company_id}/users?user_type=Account`,
    })
      .then((response) => {
        setAccounts(response.data.users);
      })
      .catch((error) => {});
  }, []);

  const handleCheckbox = (event) => {
    let data = {
      resource: event.target.dataset.resource,
      is_checked: event.target.checked,
      accountId: event.target.dataset.accountId,
    };

    instance({
      method: 'put',
      url: `/companies/${company_id}/users/${user_id}/accessibility`,
      data: data,
    })
      .then((response) => {
        setAccesses(response.data.accessibility);
      })
      .catch((error) => {});
  };

  const render_single_account_checkbox = (curr_account) => {
    let is_checked = false;
    let account_checkbox = '';
    if (accesses.viewable_accounts.length > 0) {
      accesses.viewable_accounts.forEach((single_access) => {
        if (single_access === curr_account._id) {
          is_checked = true;
        }
      });
    } else {
      is_checked = false;
    }

    account_checkbox = (
      <input type='checkbox' checked={is_checked} data-resource={'viewable_accounts'} data-account-id={curr_account._id} onChange={handleCheckbox} />
    );

    return account_checkbox;
  };

  const render_single_account = () => {
    let all_rows = [];

    if (accounts.length > 0 && Object.keys(accesses).length) {
      accounts.forEach((single_account) => {
        all_rows.push(
          <tr>
            <td>{single_account.username}</td>
            <td>{render_single_account_checkbox(single_account)}</td>
          </tr>
        );
      });
    }

    return all_rows;
  };

  const render_accounts_acl_table = () => {
    let users_table = (
      <table className='table shadow'>
        <thead className='thead-dark'>
          <tr>
            <th className='text-center' colspan='2'>
              Accounts
            </th>
          </tr>
        </thead>
        <tbody>{render_single_account()}</tbody>
      </table>
    );

    return users_table;
  };

  function check_all_checkboxes() {
    let all_accounts = document.querySelectorAll('input[type=checkbox][data-resource=viewable_accounts]');

    all_accounts.forEach((single_account) => {
      if (single_account.checked == false) {
        single_account.click();
      }
    });
  }

  return (
    <>
      <NavBar show_navs={true} />
      <div className='container'>
        <div className='row'>
          <div className='col-3'>
            <button className='btn btn-primary mt-4' onClick={check_all_checkboxes}>
              Check All
            </button>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12'>{render_accounts_acl_table()}</div>
        </div>
      </div>
    </>
  );
};

export default AccountsPermissions;
