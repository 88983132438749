import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import instance from '../../config/axios';
import NavBar from '../../components/NavBar';
import { toast } from 'react-toastify';

function NewAccountType() {
  let history = useHistory();
  let { company_id } = useParams();
  const [is_active, setActiveChecked] = useState(true);
  const [is_default, setDefaultChecked] = useState(false);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users/dummy_user_id/accessibility`,
    })
      .then((response) => {
        setPermissions(response.data.accessibility);
        if (response.data.accessibility.account_types.add === false) {
          history.push('/');
        }
      })
      .catch((error) => {});
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: `/companies/${company_id}/account_types`,
      data: formData,
    })
      .then((response) => {
        history.push(`/companies/${company_id}/account_types`);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
      });
  };

  const new_account_type_form = () => {
    let account_type_form = <></>;
    let show_page = false;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      show_page = true;
    } else if (
      localStorage.getItem('eFinanceUser') === 'Company' &&
      Object.keys(permissions).length > 0 &&
      permissions.account_types.add === true
    ) {
      show_page = true;
    }

    if (show_page) {
      account_type_form = (
        <div className='container'>
          <form className='mt-4' onSubmit={onSubmit}>
            <div className='form-group'>
              <input
                className='form-control'
                type='text'
                name='account_type[name]'
                placeholder='Name'
              />
            </div>

            <div className='form-check'>
              <input
                id='is_active'
                type='checkbox'
                checked={is_active}
                onChange={() => {
                  setActiveChecked(!is_active);
                }}
                className='form-check-input'
              />
              <label className='form-check-label' for='is_active'>
                Is Active
              </label>
              <input type='hidden' name='account_type[is_active]' value={is_active} />
            </div>

            <div className='form-check'>
              <input
                id='is_default'
                type='checkbox'
                checked={is_default}
                onChange={() => {
                  setDefaultChecked(!is_default);
                }}
                className='form-check-input'
              />
              <label className='form-check-label' for='is_default'>
                Is Default
              </label>
              <input type='hidden' name='account_type[is_default]' value={is_default} />
            </div>

            <input type='hidden' value={company_id} name='account_type[company_id]' />

            <button className='btn btn-primary mt-2'>Submit</button>
          </form>
        </div>
      );
    }

    return account_type_form;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      {new_account_type_form()}
    </>
  );
}

export default NewAccountType;
