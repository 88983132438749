import React from 'react';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import CreateIcon from '@material-ui/icons/Create';

const CompanyUser = (props) => {
  const [users, setUsers] = useState([]);
  const [show_new_user_modal, setNewUserModal] = useState(false);
  const [error_message, setError] = useState(false);
  const [show_edit_modal, setEditModal] = useState(false);
  const [edit_user, setEditUser] = useState('');

  let { company_id } = useParams();

  useEffect(() => {
    get_all_users();
  }, []);

  const get_all_users = () => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users?user_type=Company`,
    })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const render_edit_modal = () => {
    let edit_modal_html = (
      <Modal show={show_edit_modal} onHide={handleEditModal}>
        <Modal.Header closeButton>
          <b>Edit User</b>
        </Modal.Header>
        <form onSubmit={handleEditModalSubmit}>
          <Modal.Body>
            {render_error()}
            <div className='form-group'>
              <label>First Name</label>
              <input
                type='text'
                className='form-control'
                placeholder='First Name'
                name='user[first_name]'
                defaultValue={edit_user.first_name}
              />
            </div>
            <div className='form-group'>
              <label>Last Name</label>
              <input
                type='text'
                className='form-control'
                placeholder='Last Name'
                name='user[last_name]'
                defaultValue={edit_user.last_name}
              />
            </div>
            <div className='form-group'>
              <label>New Password</label>
              <input
                type='text'
                className='form-control'
                placeholder='Password'
                name='user[password]'
              />
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='user[is_deleted]'
                id='is_active_user'
                value='false'
                defaultChecked={!edit_user.is_deleted}
              />
              <label className='form-check-label' for='is_active_user'>
                Is Active
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='user[is_deleted]'
                id='is_suspended_user'
                value='true'
                defaultChecked={edit_user.is_deleted}
              />
              <label className='form-check-label' for='is_suspended_user'>
                Is Suspended
              </label>
            </div>
            {render_user_type()}
            {render_company_input()}
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={handleNewUserClose}
            >
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return edit_modal_html;
  };

  const handleEditModal = (event) => {
    if (show_edit_modal == true) {
      // It is visible right now. Hide Modal
      setEditModal(false);
    } else {
      // It is hidden right now. Show Modal
      setEditModal(true);
      let matched_user = get_matching_user(
        event.target.closest('tr').id.split('comp-user-')[1]
      );
      setEditUser(matched_user);
    }
  };

  const get_matching_user = (user_id) => {
    // find user with given id
    let matched_user = {};

    users.forEach((single_user) => {
      if (single_user._id === user_id) {
        matched_user = single_user;
      }
    });

    return matched_user;
  };

  const render_users = () => {
    let users_html = [];

    users.forEach((single_user, index) => {
      users_html.push(
        <>
          <tr id={`comp-user-${single_user._id}`}>
            <td>
              <span>{single_user.username}</span>
            </td>
            <td>
              <CreateIcon
                onClick={handleEditModal}
                style={{ cursor: 'pointer' }}
              />
            </td>
            <td>
              <Link
                to={`/companies/${company_id}/users/${single_user._id}/accessibility`}
              >
                <LockIcon style={{ cursor: 'pointer', color: '#000' }} />
              </Link>
            </td>
            <td>
              <span>{single_user.is_deleted ? 'InActive' : 'Active'}</span>
            </td>
            <td>
              <span>{single_user.first_name}</span>
            </td>
            <td>
              <span>{single_user.last_name}</span>
            </td>
            <td>
              <span>
                {moment(new Date(single_user.created_at)).format(
                  'MM/DD/YYYY HH:mm'
                )}
              </span>
            </td>
            <td>
              <span>
                {moment(new Date(single_user.last_login)).format(
                  'MM/DD/YYYY HH:mm'
                )}
              </span>
            </td>
            <td>
              <span>{single_user.last_ip}</span>
            </td>
          </tr>
        </>
      );
    });

    return users_html;
  };

  const handleNewUserClose = () => {
    setNewUserModal(false);
  };

  const handleNewUserShow = (event) => {
    setNewUserModal(true);
  };

  const handleEditModalSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    if (formData.get('user[password]') === '') {
      formData.delete('user[password]');
    }

    instance({
      method: 'put',
      url: `/companies/${company_id}/users/${edit_user._id}`,
      data: formData,
    })
      .then((response) => {
        handleEditModal();
        get_all_users();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewUserSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: `/companies/${company_id}/users`,
      data: formData,
    })
      .then((response) => {
        get_all_users();
        handleNewUserClose();
        setError(false);
      })
      .catch((error) => {
        let error_msg = error?.response?.data?.message?.message;
        if (error?.response?.data?.message?.message?.includes('to be unique')) {
          error_msg = 'Account already exists';
          setError(error_msg);
        }
        console.log(error);
      });
  };

  const render_company_input = () => {
    let company_input = (
      <>
        <input type='hidden' name='user[company_id]' value={company_id} />
      </>
    );

    return company_input;
  };

  const render_user_type = () => {
    let user_type_input = (
      <>
        <input type='hidden' name='user[user_type]' value='Company' />
      </>
    );

    return user_type_input;
  };

  const render_error = () => {
    let error_html = <></>;
    if (error_message != false) {
      error_html = (
        <div className='alert alert-danger' role='alert'>
          {error_message}
        </div>
      );
    }

    return error_html;
  };

  const render_new_user_modal = () => {
    let new_account_modal_html = (
      <Modal show={show_new_user_modal} onHide={handleNewUserClose}>
        <Modal.Header closeButton>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-8 p-0'>
                <strong>Add New User</strong>
              </div>
            </div>
          </div>
        </Modal.Header>
        <form onSubmit={handleNewUserSubmit}>
          <Modal.Body>
            {render_error()}
            <div className='form-group'>
              <label>Username</label>
              <input
                type='text'
                className='form-control'
                placeholder='Username'
                name='user[username]'
              />
            </div>
            <div className='form-group'>
              <label>Password</label>
              <input
                type='text'
                className='form-control'
                placeholder='Password'
                name='user[password]'
              />
            </div>
            <div className='form-group'>
              <label>Repeat Password</label>
              <input
                type='text'
                className='form-control'
                placeholder='Repeat Password'
              />
            </div>
            <div className='form-group'>
              <label>First Name</label>
              <input
                type='text'
                className='form-control'
                placeholder='First Name'
                name='user[first_name]'
              />
            </div>
            <div className='form-group'>
              <label>Last Name</label>
              <input
                type='text'
                className='form-control'
                placeholder='Last Name'
                name='user[last_name]'
              />
            </div>
            {render_user_type()}
            {render_company_input()}
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={handleNewUserClose}
            >
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return new_account_modal_html;
  };

  return (
    <>
      <NavBar show_navs={true} />
      {render_new_user_modal()}
      {render_edit_modal()}
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-4'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleNewUserShow}
            >
              Add New User
            </button>
          </div>
          <div className='col-4 text-center'>
            <h3>Users</h3>
          </div>
        </div>

        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>Username</th>
              <th scope='col'></th>
              <th scope='col'></th>
              <th scope='col'>User Status</th>
              <th scope='col'>First Name</th>
              <th scope='col'>Last Name</th>
              <th scope='col'>Created at</th>
              <th scope='col'>Last Login</th>
              <th scope='col'>Last IP</th>
            </tr>
          </thead>
          <tbody>{render_users()}</tbody>
        </table>
      </div>
    </>
  );
};

export default CompanyUser;
