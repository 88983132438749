import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';

const ShowLedger = (props) => {
  const [user, setUser] = useState({});
  const [positive_vals, setPositiveVals] = useState([]);
  const [negative_vals, setNegativeVals] = useState([]);
  const [total_positive, setTotalPositive] = useState(0);
  const [total_negative, setTotalNegative] = useState(0);
  const [minus_sort_order, setMinusSortOrder] = useState('asc');
  const [plus_sort_order, setPlusSortOrder] = useState('asc');
  const { company_id, user_id } = useParams();
  const [permissions, setPermissions] = useState({});

  // Fetch all account types on page load
  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users/${user_id}/ledgers`,
    })
      .then((response) => {
        setUser(response.data.user);
        setPermissions(response.data.permissions);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const handleColumnClick = (event) => {
    event.preventDefault();
    let temp_sort_column = event.target.textContent.toLowerCase();

    if (temp_sort_column === 'minus') {
      if (minus_sort_order === 'asc') {
        setMinusSortOrder('desc');
      } else if (minus_sort_order === 'desc') {
        setMinusSortOrder('asc');
      }
    } else if (temp_sort_column === 'plus') {
      if (plus_sort_order === 'asc') {
        setPlusSortOrder('desc');
      } else if (plus_sort_order === 'desc') {
        setPlusSortOrder('asc');
      }
    }
  };

  useEffect(() => {
    if (minus_sort_order === 'desc') {
      negative_vals.sort(function (first_item, second_item) {
        return parseFloat(second_item.balance) - parseFloat(first_item.balance);
      });
    } else if (minus_sort_order === 'asc') {
      negative_vals.sort(function (first_item, second_item) {
        return parseFloat(first_item.balance) - parseFloat(second_item.balance);
      });
    }

    setNegativeVals(negative_vals);
  }, [minus_sort_order]);

  useEffect(() => {
    if (plus_sort_order === 'desc') {
      positive_vals.sort(function (first_item, second_item) {
        return parseFloat(second_item.balance) - parseFloat(first_item.balance);
      });
    } else if (plus_sort_order === 'asc') {
      positive_vals.sort(function (first_item, second_item) {
        return parseFloat(first_item.balance) - parseFloat(second_item.balance);
      });
    }

    setPositiveVals(positive_vals);
  }, [plus_sort_order]);

  useEffect(() => {
    calculate_total();
  }, [user]);

  const calculate_total = () => {
    let positive_values = [];
    let negative_values = [];
    let set_total_balance = 0;
    let total_pos = 0;
    let total_neg = 0;

    user.sub_accounts?.forEach((single_sub_account) => {
      if (single_sub_account.balance?.$numberDecimal > 0) {
        positive_values.push({
          sub_account_id: single_sub_account._id,
          account_username: single_sub_account.account_username,
          balance: parseFloat(single_sub_account.balance?.$numberDecimal),
        });

        total_pos += parseFloat(single_sub_account.balance?.$numberDecimal);
      } else if (single_sub_account.balance?.$numberDecimal < 0) {
        negative_values.push({
          sub_account_id: single_sub_account._id,
          account_username: single_sub_account.account_username,
          balance: parseFloat(single_sub_account.balance?.$numberDecimal),
        });

        total_neg += parseFloat(single_sub_account.balance?.$numberDecimal);
      }
      set_total_balance += parseFloat(
        single_sub_account.balance?.$numberDecimal
      );
    });

    let ledger_balancer = total_pos + total_neg;
    if (set_total_balance >= 0) {
      negative_values.push({
        account_username: 'Balance',
        balance: -ledger_balancer,
      });

      total_neg += -ledger_balancer;
    } else if (set_total_balance < 0) {
      let positive_balancer = total_neg + total_pos;
      positive_values.push({
        account_username: 'Balance',
        balance: Math.abs(positive_balancer),
      });

      total_pos -= positive_balancer;
    }

    setNegativeVals(negative_values);
    setTotalPositive(total_pos);
    setTotalNegative(total_neg);
    setPositiveVals(positive_values);
  };

  const render_statement = (pos_neg) => {
    let all_rows = [];

    if (pos_neg === 'positive') {
      positive_vals.forEach((single_val) => {
        all_rows.push(
          <tr>
            {single_val.account_username === 'Balance' ? (
              <td>{single_val.account_username}</td>
            ) : (
              <td>
                <Link
                  to={`/companies/${company_id}/sub_accounts/${single_val.sub_account_id}/account_statements`}
                >
                  {single_val.account_username}
                </Link>
              </td>
            )}
            <td
              className={
                parseFloat(single_val.balance) > 0 ? 'plus-color' : 'neg-color'
              }
            >
              {new Intl.NumberFormat().format(single_val.balance)}
            </td>
          </tr>
        );
      });
    } else if (pos_neg === 'negative') {
      negative_vals.forEach((single_val) => {
        all_rows.push(
          <tr>
            {single_val.account_username === 'Balance' ? (
              <td>{single_val.account_username}</td>
            ) : (
              <td>
                <Link
                  to={`/companies/${company_id}/sub_accounts/${single_val.sub_account_id}/account_statements`}
                >
                  {single_val.account_username}
                </Link>
              </td>
            )}

            <td
              className={
                parseFloat(single_val.balance) > 0 ? 'plus-color' : 'neg-color'
              }
            >
              {new Intl.NumberFormat().format(single_val.balance)}
            </td>
          </tr>
        );
      });
    }

    return all_rows;
  };

  const render_users_table = (pos_neg) => {
    let users_table = (
      <table className='table shadow'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>Account</th>
            <th
              scope='col'
              style={{
                cursor: 'pointer',
              }}
              onClick={handleColumnClick}
            >
              <span>⇅</span>
              <span>{pos_neg === 'positive' ? 'Plus' : 'Minus'}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {render_statement(pos_neg)}
          <tr className='thead-dark'>
            <th></th>
            <th className={pos_neg === 'positive' ? 'plus-color' : 'neg-color'}>
              {pos_neg === 'positive'
                ? new Intl.NumberFormat().format(total_positive)
                : new Intl.NumberFormat().format(total_negative)}
            </th>
          </tr>
        </tbody>
      </table>
    );

    return users_table;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />

      <div className='container'>
        <div className='row mt-4'>
          <div className='offset-4 col-4 text-center'>
            <h3> Ledger </h3>
          </div>
          <div className='offset-2 col-2'>
            <input
              className='form-control'
              type='date'
              name='trip-start'
              defaultValue={new Date().toISOString().split('T')[0]}
              disabled
            />
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-12 text-center'>
            <h3> {user.username} </h3>
          </div>
        </div>

        <div className='row'>
          <div className='col-6'>{render_users_table('negative')}</div>
          <div className='col-6'>{render_users_table('positive')}</div>
        </div>
      </div>
    </>
  );
};

export default ShowLedger;
