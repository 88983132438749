import React from 'react';
import { useHistory } from 'react-router-dom';
import instance from '../../config/axios';
import NavBar from '../../components/NavBar';

function NewCompany() {
  let history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: 'companies',
      data: formData,
    })
      .then((response) => {
        history.push('/companies');
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const new_company_form = () => {
    let bank_form = (
      <div className='container'>
        <form className='mt-4' onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              className='form-control'
              type='text'
              name='company[name]'
              placeholder='Name'
            />
          </div>

          <button className='btn btn-primary'>Submit</button>
        </form>
      </div>
    );

    return bank_form;
  };

  return (
    <>
      <NavBar show_navs={false} /> {new_company_form()}
    </>
  );
}

export default NewCompany;
