import React from 'react';
import { Link, useParams } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import AccessCheckbox from './access_checkbox';
import NavBar from '../../components/NavBar';

const Accessibility = (props) => {
  const [accesses, setAccesses] = useState({});
  const { company_id, user_id } = useParams();

  useEffect(() => {
    // Fetch all acl rules
    instance({
      method: 'get',
      url: `/companies/${company_id}/users/${user_id}/accessibility`,
    })
      .then((response) => {
        setAccesses(response.data.accessibility);
      })
      .catch((error) => {});
  }, []);

  const handleCheckbox = (event) => {
    let data = {
      action: event.target.dataset.action,
      resource: event.target.dataset.resource,
      is_checked: event.target.checked,
    };

    instance({
      method: 'put',
      url: `/companies/${company_id}/users/${user_id}/accessibility`,
      data: data,
    })
      .then((response) => {
        setAccesses(response.data.accessibility);
      })
      .catch((error) => {});
  };

  const render_acl_table = () => {
    let users_table = (
      <table className='table shadow'>
        <thead className='thead-dark'>
          <tr className='text-center'>
            <th scope='col'></th>
            <th scope='col'>View</th>
            <th scope='col'>Edit</th>
            <th scope='col'>Add</th>
          </tr>
        </thead>
        <tbody>
          <tr className='text-center'>
            <th
              scope='col'
              style={{
                borderColor: '#454d55',
                color: '#fff',
                background: '#343a40',
              }}
            >
              Accounts
            </th>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='accounts'
                action='view'
                handleCheckbox={handleCheckbox}
              />
            </td>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='accounts'
                action='edit'
                handleCheckbox={handleCheckbox}
              />
            </td>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='accounts'
                action='add'
                handleCheckbox={handleCheckbox}
              />
            </td>
          </tr>
          <tr className='text-center'>
            <th
              scope='col'
              style={{
                borderColor: '#454d55',
                color: '#fff',
                background: '#343a40',
              }}
            >
              Transactions
            </th>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='transactions'
                action='view'
                handleCheckbox={handleCheckbox}
              />
            </td>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='transactions'
                action='edit'
                handleCheckbox={handleCheckbox}
              />
            </td>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='transactions'
                action='add'
                handleCheckbox={handleCheckbox}
              />
            </td>
          </tr>
          <tr className='text-center'>
            <th
              scope='col'
              style={{
                borderColor: '#454d55',
                color: '#fff',
                background: '#343a40',
              }}
            >
              Account Types
            </th>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='account_types'
                action='view'
                handleCheckbox={handleCheckbox}
              />
            </td>
            <td></td>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='account_types'
                action='add'
                handleCheckbox={handleCheckbox}
              />
            </td>
          </tr>
          <tr className='text-center'>
            <th
              scope='col'
              style={{
                borderColor: '#454d55',
                color: '#fff',
                background: '#343a40',
              }}
            >
              Balance Sheet
            </th>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='balance_sheet'
                action='view'
                handleCheckbox={handleCheckbox}
              />
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr className='text-center'>
            <th
              scope='col'
              style={{
                borderColor: '#454d55',
                color: '#fff',
                background: '#343a40',
              }}
            >
              Import
            </th>
            <td></td>
            <td></td>
            <td>
              <AccessCheckbox
                accesses={accesses}
                resource='import'
                action='add'
                handleCheckbox={handleCheckbox}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );

    return users_table;
  };

  return (
    <>
      <NavBar show_navs={true} />
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='offset-md-3 col-6 text-center'>
            <h3> Access Control List </h3>
          </div>
          <div
            className={'col-3 d-flex align-items-center justify-content-end'}
          >
            <Link
              to={`/companies/${company_id}/users/${user_id}/accounts`}
              className='btn btn-primary'
            >
              Accounts Access
            </Link>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>{render_acl_table()}</div>
        </div>
      </div>
    </>
  );
};

export default Accessibility;
