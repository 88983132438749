import React from 'react';
import { useParams } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';

const AccountStatementsList = (props) => {
  const [sub_account, setSubAccount] = useState({});
  const [permissions, setPermissions] = useState({});
  const [start_date, setStartDate] = useState(new Date().toISOString());
  const [end_date, setEndDate] = useState(new Date().toISOString());
  const { company_id, sub_account_id } = useParams();

  // Fetch all account types on page load
  useEffect(async () => {
    await instance({
      method: 'get',
      url: `/companies/${company_id}/users/dummy_user_id/accessibility`,
    })
      .then((response) => {
        setPermissions(response.data.accessibility);
      })
      .catch((error) => {});

    instance({
      method: 'get',
      url: `/companies/${company_id}/sub_accounts/${sub_account_id}/account_statements`,
    })
      .then((response) => {
        setSubAccount(response.data.sub_account);
        window.scrollTo(0, document.body.scrollHeight);
      })
      .catch((error) => {
        // console.log(error.response.data.message);
      });
  }, []);

  const render_single_statement = () => {
    let all_rows = [];

    sub_account?.account_statements?.forEach((single_val) => {
      all_rows.push(
        <>
          <tr>
            <td>{single_val.transaction_date?.split('T')[0].split('-').reverse().join('-')}</td>
            <td>{single_val.username}</td>
            <td>{single_val.other_sub_account_username}</td>
            <td>{single_val.narration}</td>
            <td className={parseFloat(new Intl.NumberFormat().format(single_val.amount.$numberDecimal)) > 0 ? 'plus-color' : 'neg-color'}>
              {single_val.amount.$numberDecimal < 0 ? new Intl.NumberFormat().format(single_val.amount.$numberDecimal) : ''}
            </td>
            <td className={parseFloat(new Intl.NumberFormat().format(single_val.amount.$numberDecimal)) > 0 ? 'plus-color' : 'neg-color'}>
              {single_val.amount.$numberDecimal > 0 ? new Intl.NumberFormat().format(single_val.amount.$numberDecimal) : ''}
            </td>
            <td className={parseFloat(new Intl.NumberFormat().format(single_val.balance.$numberDecimal)) > 0 ? 'plus-color' : 'neg-color'}>
              {new Intl.NumberFormat().format(single_val.balance.$numberDecimal)}
            </td>
            <td>{single_val.notes}</td>
          </tr>
        </>
      );
    });

    return all_rows;
  };

  const get_opening_balance = () => {
    let opening_balance_date = '';
    let from_date = document.getElementById('from_date')?.value;
    let to_date = document.getElementById('to_date')?.value;

    if (from_date === undefined || to_date === undefined) {
      // Do Nothing
      opening_balance_date = new Date().toISOString().split('T')[0].split('-').reverse().join('-');
    } else {
      if (from_date === '' || to_date === '') {
        opening_balance_date = new Date().toISOString().split('T')[0].split('-').reverse().join('-');
      } else if (from_date != '' && to_date != '') {
        opening_balance_date = from_date.split('T')[0].split('-').reverse().join('-');
      }
    }

    if (sub_account?.account_statements?.length > 0) {
      let opening_bal_row = sub_account?.account_statements[0];
      return [
        parseFloat(opening_bal_row.balance.$numberDecimal) - parseFloat(opening_bal_row.amount.$numberDecimal),
        opening_balance_date,
        // opening_bal_row.created_at.split('T')[0].split('-').reverse().join('-'),
      ];
    } else {
      return [0, opening_balance_date];
    }
  };

  const get_col_total = (col_name) => {
    let col_total = 0;
    let open_bal = parseFloat(get_opening_balance()[0]);

    sub_account?.account_statements?.forEach((single_val) => {
      if (col_name === 'take') {
        if (parseFloat(single_val.amount.$numberDecimal) < 0) {
          col_total += parseFloat(single_val.amount.$numberDecimal);
        }
      } else if (col_name === 'give') {
        if (parseFloat(single_val.amount.$numberDecimal) > 0) {
          col_total += parseFloat(single_val.amount.$numberDecimal);
        }
      }
    });

    if (col_name === 'take') {
      if (open_bal < 0) {
        col_total += open_bal;
      }
    } else if (col_name === 'give') {
      if (open_bal > 0) {
        col_total += open_bal;
      }
    }

    return col_total;
  };

  const render_statements_table = () => {
    let take_total = get_col_total('take');
    let give_total = get_col_total('give');

    let users_table = (
      <table className='table shadow'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>Date</th>
            <th scope='col'>Account</th>
            <th scope='col'>Sub-Account</th>
            <th scope='col'>Narration</th>
            <th scope='col'>Minus</th>
            <th scope='col'>Plus</th>
            <th scope='col'>Balance</th>
            <th scope='col'>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{get_opening_balance()[1]}</td>
            <td>O/B</td>
            <td></td>
            <td>Opening Balance</td>
            <td>{parseFloat(get_opening_balance()[0]) < 0 ? get_opening_balance()[0] : 0}</td>
            <td>{parseFloat(get_opening_balance()[0]) > 0 ? get_opening_balance()[0] : 0}</td>
            <th scope='col'>{get_opening_balance()[0]}</th>
          </tr>
          {render_single_statement()}
          <tr
            style={{
              backgroundColor: '#343a40',
              color: '#fff',
            }}
          >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className={parseFloat(new Intl.NumberFormat().format(take_total)) > 0 ? 'plus-color' : 'neg-color'}>
              {new Intl.NumberFormat().format(take_total)}
            </td>
            <td className={parseFloat(new Intl.NumberFormat().format(give_total)) > 0 ? 'plus-color' : 'neg-color'}>
              {new Intl.NumberFormat().format(give_total)}
            </td>
            <th scope='col' className={parseFloat(new Intl.NumberFormat().format(take_total + give_total)) > 0 ? 'plus-color' : 'neg-color'}>
              {new Intl.NumberFormat().format(take_total + give_total)}
            </th>
            <td></td>
          </tr>
        </tbody>
      </table>
    );

    return users_table;
  };

  const handleDateFilters = (event) => {
    event.preventDefault();
    let api_url = `/companies/${company_id}/sub_accounts/${sub_account_id}/account_statements?start_date=${start_date}&end_date=${end_date}`;

    instance({
      method: 'get',
      url: api_url,
    })
      .then((response) => {
        setSubAccount(response.data.sub_account);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      <div className='container'>
        <div className='row mt-4'>
          <div className='col-12'>
            <h3> Account Statement </h3>
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-6'>
            <h3> {sub_account.account_username} </h3>
          </div>

          <div className='col-6'>
            <form className='d-flex' style={{ alignItems: 'center', justifyContent: 'space-between' }} onSubmit={handleDateFilters}>
              <div>
                <label className='form-check-label mr-2'>From</label>
                <input
                  type='date'
                  name='from_date'
                  id='from_date'
                  required
                  onChange={(event) => {
                    setStartDate(event.target.value);
                  }}
                />
              </div>
              <div>
                <label className='form-check-label mr-2'>To</label>
                <input
                  type='date'
                  name='to_date'
                  id='to_date'
                  required
                  onChange={(event) => {
                    setEndDate(event.target.value);
                  }}
                />
              </div>
              <div>
                <input type='submit' value='Submit' className='btn btn-primary btn-sm' />
              </div>
            </form>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>{render_statements_table()}</div>
        </div>
      </div>
    </>
  );
};

export default AccountStatementsList;
