import React from 'react';
import { Link } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';

const Banks = (props) => {
  const [bank_types, setBanks] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);

  // Fetch all banks on page load
  useEffect(() => {
    instance({
      method: 'get',
      url: 'bank_types',
    })
      .then((response) => {
        setBanks(response.data.bank_types);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, [apiResponse]);

  const onDelete = (event) => {
    let id = event.target.dataset.id;

    instance({
      method: 'delete',
      url: `/bank_types/${id}`,
    })
      .then((response) => {
        setApiResponse((prevRes) => !prevRes);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const render_banks = () => {
    let banks_html = [];
    bank_types.forEach((single_bank, index) => {
      banks_html.push(
        <>
          <tr>
            <td>{index + 1}</td>
            <td>{single_bank.name}</td>
            <td>
              <button
                className='btn btn-danger mr-2'
                data-id={single_bank.id}
                onClick={onDelete}
              >
                Delete
              </button>
            </td>
          </tr>
        </>
      );
    });
    return banks_html;
  };

  return (
    <>
      <NavBar show_navs={true} />
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-12 d-flex justify-content-between'>
            <h3>Banks</h3>
            <Link to='/banks/new' className='btn btn-primary float-right'>
              Add Bank
            </Link>
          </div>
        </div>

        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Name</th>
              <th scope='col'>Actions</th>
            </tr>
          </thead>
          <tbody>{render_banks()}</tbody>
        </table>
      </div>
    </>
  );
};

export default Banks;
