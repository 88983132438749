import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function HomeRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem('eFinanceToken')) {
          return <Component {...props} />;
        } else {
          if (localStorage.getItem('eFinanceUser') === 'Admin') {
            return <Redirect to='/companies' />;
          } else if (localStorage.getItem('eFinanceUser') === 'Company') {
            let company_id = localStorage.getItem('company_id');
            // return <Redirect to={`/companies/${company_id}`} />;
            return <Redirect to={`/companies/${company_id}/welcome`} />;
          }
        }
      }}
    />
  );
}

export default HomeRoute;
