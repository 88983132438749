import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import instance from '../../config/axios';
import { toast } from 'react-toastify';
import lodash from 'lodash';

const ShowCompany = () => {
  const [users, setUsers] = useState([]);
  const [negative_users, setNegUsers] = useState([]);
  const [positive_users, setPosUsers] = useState([]);
  const [minus_sort_order, setMinusSortOrder] = useState('asc');
  const [plus_sort_order, setPlusSortOrder] = useState('asc');

  const [plus_acc_sort_order, setPlusAccSortOrder] = useState('asc');
  const [negative_acc_sort_order, setNegativeAccSortOrder] = useState('asc');

  const [permissions, setPermissions] = useState({});
  let { company_id } = useParams();
  let history = useHistory();

  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users?user_type=Account&balance_sheet_view=true`,
    })
      .then((response) => {
        setUsers(response.data.users);
        setPermissions(response.data.permissions);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
      });
  }, []);

  useEffect(() => {
    let sortedValues = [];
    let negative_user = [...negative_users];
    if (minus_sort_order === 'desc') {
      sortedValues = negative_user.sort(function (first_item, second_item) {
        return parseFloat(second_item.balance?.$numberDecimal) - parseFloat(first_item.balance?.$numberDecimal);
      });
    } else if (minus_sort_order === 'asc') {
      sortedValues = negative_user.sort(function (first_item, second_item) {
        return parseFloat(first_item.balance?.$numberDecimal) - parseFloat(second_item.balance?.$numberDecimal);
      });
    }
    setNegUsers(sortedValues);
  }, [minus_sort_order]);

  useEffect(() => {
    let sortedValues = [];
    let positive_user = [...positive_users];
    if (plus_sort_order === 'desc') {
      sortedValues = positive_user.sort(function (first_item, second_item) {
        return parseFloat(second_item.balance?.$numberDecimal) - parseFloat(first_item.balance?.$numberDecimal);
      });
    } else if (plus_sort_order === 'asc') {
      sortedValues = positive_user.sort(function (first_item, second_item) {
        return parseFloat(first_item.balance?.$numberDecimal) - parseFloat(second_item.balance?.$numberDecimal);
      });
    }

    setPosUsers(sortedValues);
  }, [plus_sort_order]);

  useEffect(() => {
    const pos_users_temp = [...positive_users];
    let sortedUser = [];

    if (plus_acc_sort_order === 'desc') {
      sortedUser = lodash.orderBy(pos_users_temp, ['username'], ['asc']);
    } else if (plus_acc_sort_order === 'asc') {
      sortedUser = lodash.orderBy(pos_users_temp, ['username'], ['desc']);
    }

    setPosUsers(sortedUser);
  }, [plus_acc_sort_order]);

  useEffect(() => {
    const neg_users_temp = [...negative_users];
    let sortedUser = [];

    if (negative_acc_sort_order === 'desc') {
      sortedUser = lodash.orderBy(neg_users_temp, ['username'], ['asc']);
    } else if (negative_acc_sort_order === 'asc') {
      sortedUser = lodash.orderBy(neg_users_temp, ['username'], ['desc']);
    }

    setNegUsers(sortedUser);
  }, [negative_acc_sort_order]);

  useEffect(() => {
    let temp_pos_users = [];
    let temp_neg_users = [];
    users.forEach((single_user) => {
      if (single_user.balance?.$numberDecimal > 0) {
        temp_pos_users.push(single_user);
      } else if (single_user.balance?.$numberDecimal < 0) {
        temp_neg_users.push(single_user);
      }
    });

    setPosUsers(temp_pos_users);
    setNegUsers(temp_neg_users);
  }, [users]);

  const handleColumnClick = (event) => {
    event.preventDefault();
    let temp_sort_column = event.target.textContent.toLowerCase();
    if (temp_sort_column === 'minus') {
      if (minus_sort_order === 'asc') {
        setMinusSortOrder('desc');
      } else if (minus_sort_order === 'desc') {
        setMinusSortOrder('asc');
      }
    } else if (temp_sort_column === 'plus') {
      if (plus_sort_order === 'asc') {
        setPlusSortOrder('desc');
      } else if (plus_sort_order === 'desc') {
        setPlusSortOrder('asc');
      }
    }
  };

  const handleSortPos = (event) => {
    event.preventDefault();
    if (plus_acc_sort_order === 'asc') {
      setPlusAccSortOrder('desc');
    } else if (plus_acc_sort_order === 'desc') {
      setPlusAccSortOrder('asc');
    }
  };

  const handleSortNeg = (event) => {
    event.preventDefault();
    if (negative_acc_sort_order === 'asc') {
      setNegativeAccSortOrder('desc');
    } else if (negative_acc_sort_order === 'desc') {
      setNegativeAccSortOrder('asc');
    }
  };

  const render_users_table = (pos_neg) => {
    let users_table = (
      <>
        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th
                scope='col'
                style={{
                  cursor: 'pointer',
                }}
                onClick={pos_neg === 'positive' ? handleSortPos : handleSortNeg}
              >
                <span>⇅</span>Account
              </th>
              <th
                scope='col'
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleColumnClick}
              >
                <span>⇅</span>
                <span>{pos_neg === 'positive' ? 'Plus' : 'Minus'}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {render_users(pos_neg)}
            <tr className='thead-dark'>
              <th></th>
              <th className={parseFloat(get_total(pos_neg)) > 0 ? 'plus-color' : 'neg-color'}>{get_total(pos_neg)}</th>
            </tr>
          </tbody>
        </table>
      </>
    );

    return users_table;
  };

  const get_total = (pos_neg) => {
    let total_balance = 0;

    users.forEach((user, index) => {
      if (is_accessible_user(user)) {
        if (pos_neg === 'positive' && user.balance?.$numberDecimal > 0) {
          total_balance += parseFloat(user.balance?.$numberDecimal);
        } else if (pos_neg === 'negative' && user.balance?.$numberDecimal < 0) {
          total_balance += parseFloat(user.balance?.$numberDecimal);
        }
      }
    });

    return new Intl.NumberFormat().format(total_balance);
  };

  const is_accessible_user = (curr_user) => {
    let is_accessible = false;

    if (Object.keys(permissions).length) {
      permissions.viewable_accounts.forEach((single_acc) => {
        if (single_acc == curr_user._id) {
          is_accessible = true;
        }
      });
    }

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      is_accessible = true;
    }

    return is_accessible;
  };

  const render_users = (pos_neg) => {
    let users_html = [];

    if (pos_neg === 'positive') {
      positive_users.forEach((user, index) => {
        if (is_accessible_user(user)) {
          users_html.push(
            <tr>
              <td>
                <Link to={`/companies/${company_id}/users/${user._id}/ledger`}>{user.username}</Link>
              </td>
              <td className={parseFloat(user.balance?.$numberDecimal) > 0 ? 'plus-color' : 'neg-color'}>
                {new Intl.NumberFormat().format(parseInt(user.balance?.$numberDecimal))}
              </td>
            </tr>
          );
        }
      });
    } else if (pos_neg === 'negative') {
      negative_users.forEach((user, index) => {
        if (is_accessible_user(user)) {
          users_html.push(
            <tr>
              <td>
                <Link to={`/companies/${company_id}/users/${user._id}/ledger`}>{user.username}</Link>
              </td>
              <td className={parseFloat(user.balance?.$numberDecimal) > 0 ? 'plus-color' : 'neg-color'}>
                {new Intl.NumberFormat().format(parseInt(user.balance?.$numberDecimal))}
              </td>
            </tr>
          );
        }
      });
    }

    return users_html;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />

      <div className='container'>
        <div className='row mb-4 mt-4'>
          <div className='offset-4 col-4 text-center'>
            <h3> Balance Sheet </h3>
          </div>
          <div className='offset-2 col-2'>
            <input className='form-control' type='date' name='trip-start' defaultValue={new Date().toISOString().split('T')[0]} disabled />
          </div>
        </div>

        <div className='row'>
          <div className='col-6'>{render_users_table('negative')}</div>
          <div className='col-6'>{render_users_table('positive')}</div>
        </div>
      </div>
    </>
  );
};

export default ShowCompany;
