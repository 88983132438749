import React from 'react';

const AccessCheckbox = (props) => {
  const render_checkbox = () => {
    let checkbox_html = '';
    if (Object.keys(props.accesses).length > 0) {
      if (props.resource === 'accounts') {
        if (props.action === 'no_access') {
          if (props.accesses.accounts.no_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'view') {
          if (props.accesses.accounts.view) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'edit') {
          if (props.accesses.accounts.edit) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'add') {
          if (props.accesses.accounts.add) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'full_access') {
          if (props.accesses.accounts.full_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        }
      } else if (props.resource === 'transactions') {
        if (props.action === 'no_access') {
          if (props.accesses.transactions.no_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'view') {
          if (props.accesses.transactions.view) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'edit') {
          if (props.accesses.transactions.edit) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'add') {
          if (props.accesses.transactions.add) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'full_access') {
          if (props.accesses.transactions.full_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        }
      } else if (props.resource === 'account_types') {
        if (props.action === 'no_access') {
          if (props.accesses.account_types.no_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'view') {
          if (props.accesses.account_types.view) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'edit') {
          if (props.accesses.account_types.edit) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'add') {
          if (props.accesses.account_types.add) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'full_access') {
          if (props.accesses.account_types.full_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        }
      } else if (props.resource === 'balance_sheet') {
        if (props.action === 'no_access') {
          if (props.accesses.balance_sheet.no_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'view') {
          if (props.accesses.balance_sheet.view) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'edit') {
          if (props.accesses.balance_sheet.edit) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'add') {
          if (props.accesses.balance_sheet.add) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'full_access') {
          if (props.accesses.balance_sheet.full_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        }
      } else if (props.resource === 'import') {
        if (props.action === 'no_access') {
          if (props.accesses.import.no_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'view') {
          if (props.accesses.import.view) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'edit') {
          if (props.accesses.import.edit) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'add') {
          if (props.accesses.import.add) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        } else if (props.action === 'full_access') {
          if (props.accesses.import.full_access) {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={true}
              />
            );
          } else {
            checkbox_html = (
              <input
                type='checkbox'
                data-action={props.action}
                data-resource={props.resource}
                onChange={props.handleCheckbox}
                checked={false}
              />
            );
          }
        }
      }
    }

    return checkbox_html;
  };

  return <>{render_checkbox()}</>;
};

export default AccessCheckbox;
