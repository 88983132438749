import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';

const render_companies_nav = () => {
  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    return (
      <li className='nav-item'>
        <Link className='nav-link' to={'/companies'}>
          Companies
        </Link>
      </li>
    );
  }
};

const render_currencies_tab = () => {
  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    return (
      <li className='nav-item'>
        <Link className='nav-link' to={'/currencies'}>
          Currencies
        </Link>
      </li>
    );
  }
};

const render_import_nav = (company_id, permissions) => {
  let import_nav = <></>;

  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    import_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/import`}>
          Import
        </Link>
      </li>
    );
  } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length > 0 && permissions.import.add) {
    import_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/import`}>
          Import
        </Link>
      </li>
    );
  }

  return import_nav;
};

const render_users_nav = (company_id) => {
  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    return (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/users`}>
          Users
        </Link>
      </li>
    );
  }
};

const render_transactions_nav = (company_id, permissions) => {
  let transactions_nav = <></>;

  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    transactions_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/transactions`}>
          Transactions
        </Link>
      </li>
    );
  } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length > 0 && permissions.transactions.view) {
    transactions_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/transactions`}>
          Transactions
        </Link>
      </li>
    );
  }

  return transactions_nav;
};

const render_acc_types_nav = (company_id, permissions) => {
  let acc_types_nav = <></>;

  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    acc_types_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/account_types`}>
          Account Types
        </Link>
      </li>
    );
  } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length > 0 && permissions.account_types.view) {
    acc_types_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/account_types`}>
          Account Types
        </Link>
      </li>
    );
  }

  return acc_types_nav;
};

const render_accounts_nav = (company_id, permissions) => {
  let accounts_nav = <></>;

  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    accounts_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/accounts`}>
          Accounts
        </Link>
      </li>
    );
  } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length > 0 && permissions.accounts.view) {
    accounts_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}/accounts`}>
          Accounts
        </Link>
      </li>
    );
  }

  return accounts_nav;
};

const render_balance_sheet_nav = (company_id, permissions) => {
  let balance_sheet_nav = <></>;

  if (localStorage.getItem('eFinanceUser') === 'Admin') {
    balance_sheet_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}`}>
          Balance Sheet
        </Link>
      </li>
    );
  } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length > 0 && permissions.balance_sheet.view) {
    balance_sheet_nav = (
      <li className='nav-item'>
        <Link className='nav-link' to={`/companies/${company_id}`}>
          Balance Sheet
        </Link>
      </li>
    );
  }

  return balance_sheet_nav;
};

function NavBar(props) {
  let history = useHistory();
  let { company_id } = useParams();

  return (
    <div>
      <Navbar bg='dark' variant='dark'>
        <Link className='navbar-brand' to='/'>
          Home
        </Link>

        <div className='collapse navbar-collapse'>
          <ul className='navbar-nav mr-auto'>
            {props.show_navs === false ? (
              <></>
            ) : (
              <>
                {render_companies_nav()}
                {render_accounts_nav(company_id, props.permissions)}
                {render_transactions_nav(company_id, props.permissions)}
                {render_acc_types_nav(company_id, props.permissions)}
                {render_balance_sheet_nav(company_id, props.permissions)}
                {render_import_nav(company_id, props.permissions)}
                {render_users_nav(company_id)}
              </>
            )}

            {/* {render_currencies_tab()} */}

            <li className='nav-item'>
              <Link
                className='nav-link'
                to={'/'}
                onClick={() => {
                  localStorage.removeItem('eFinanceToken');
                  localStorage.removeItem('eFinanceUser');
                  localStorage.removeItem('company_id');
                  history.push('/');
                }}
              >
                Logout
              </Link>
            </li>
          </ul>

          <span className='navbar-text'>{localStorage.getItem('username')} </span>
        </div>
      </Navbar>
    </div>
  );
}

export default NavBar;
