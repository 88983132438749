import axios from 'axios';
import { REACT_APP_SERVER_IP, REACT_APP_API_VERSION } from './env.js';
import { useHistory } from 'react-router-dom';

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: `${REACT_APP_SERVER_IP}${REACT_APP_API_VERSION}`,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    if (config.url.includes('/import_data')) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      };
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
    }

    // Do something before request is sent
    if (!config.url.includes('/login')) {
      // All urls excepts login
      config.headers.Authorization = localStorage.getItem('eFinanceToken');
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.data?.message?.name === 'TokenExpiredError') {
      let history = useHistory();

      localStorage.removeItem('eFinanceToken');
      localStorage.removeItem('eFinanceUser');
      localStorage.removeItem('company_id');
      history.push('/');
    }
    return Promise.reject(error);
  }
);

export default instance;
