import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import instance from '../config/axios';

function Welcome(props) {
  const { company_id } = useParams();
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users/dummy_user_id/accessibility`,
    })
      .then((response) => {
        setPermissions(response.data.accessibility);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      <div className='container'>
        <div className='row'>
          <div className='col-12 mt-4 text-center'>
            <h1>Welcome</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
