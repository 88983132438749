import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import NavBar from '../../components/NavBar';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import FilterListIcon from '@material-ui/icons/FilterList';
import { toast } from 'react-toastify';

const Accounts = (props) => {
  const [users, setUsers] = useState([]);
  const [new_user_pk, setNewUserPK] = useState(0);
  const [new_sub_acc_pk, setNewSubAccPK] = useState(0);
  const [account_types, setAccountTypes] = useState([]);
  const [show_new_account_modal, setNewAccountModal] = useState(false);
  const [show_new_sub_account_modal, setNewSubAccountModal] = useState(false);
  const [show_filters, setFiltersShow] = useState(false);
  const [selected_filter, setAccountFilter] = useState('');
  const [search_value, setSearchVal] = useState('');
  const [edit_user_notes_id, setEditUserNotes] = useState(null);
  const [edit_sub_account_notes_id, setEditSubAccountNotes] = useState(null);
  const [delete_sub_account_id, setDeleteSubAccount] = useState(null);
  const [show_sub_acc_notes_modal, setSubAccountNotesModal] = useState(false);
  const [show_sub_acc_delete_modal, setSubAccountDeleteModal] = useState(false);
  const [show_notes_modal, setNotesModal] = useState(false);
  const [error_message, setError] = useState(false);
  const [sort_order, setSortOrder] = useState('');
  const [sort_column, setSortColumn] = useState('');
  const [permissions, setPermissions] = useState({});

  let { company_id } = useParams();
  let history = useHistory();

  useEffect(async () => {
    if (localStorage.getItem('eFinanceUser') === 'Company') {
      await instance({
        method: 'get',
        url: `/companies/${company_id}/users/dummy_user_id/accessibility`,
      })
        .then((response) => {
          setPermissions(response.data.accessibility);
          if (response.data.accessibility.accounts.view_only === false) {
            history.push('/');
          }
        })
        .catch((error) => {});
    }

    get_all_accounts();
    get_account_types();
    get_users_total_balance();
  }, []);

  useEffect(() => {
    get_all_accounts();
    get_account_types();
    get_users_total_balance();
  }, [sort_order]);

  const get_users_total_balance = () => {
    let balance = 0;
    users.forEach((single_user) => {
      balance += parseFloat(single_user.balance.$numberDecimal);
    });
    return balance;
  };

  const get_account_types_json = () => {
    let account_types_json = [];
    account_types.forEach((single_account) => {
      account_types_json.push({
        label: single_account.name,
        value: single_account._id,
        key: single_account._id,
      });
    });

    return account_types_json;
  };

  const get_users_json = () => {
    let users_json = [];
    users.forEach((single_user) => {
      users_json.push({
        label: single_user.username,
        value: single_user._id,
        key: single_user._id,
      });
    });

    return users_json;
  };

  const get_account_types = () => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/account_types`,
    })
      .then((response) => {
        setAccountTypes(response.data.account_types);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const get_all_accounts = () => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/users?user_type=Account&sort_order=${sort_order}&sort_column=${sort_column}`,
    })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
        console.log(error.message);
      });
  };

  const find_account_type_name = (sub_account_param) => {
    let acc_type_name = '';
    account_types?.forEach((single_account) => {
      if (sub_account_param.account_type_id === single_account._id) {
        acc_type_name = single_account.name;
      }
    });

    return acc_type_name;
  };

  const render_sub_accounts = (user) => {
    let sub_accounts_rows = [];

    user.sub_accounts.forEach((single_account, index) => {
      sub_accounts_rows.push(
        <>
          <tr className={`sub-account-row sub-acc-row-${user._id} d-none`}>
            <td>
              <li>{single_account.sub_account_pk}</li>
            </td>
            <td>
              <li>{single_account.account_username}</li>
            </td>
            <td className={parseFloat(new Intl.NumberFormat().format(single_account.balance.$numberDecimal)) > 0 ? 'plus-color' : 'neg-color'}>
              <li>{new Intl.NumberFormat().format(parseInt(single_account.balance.$numberDecimal))}</li>
            </td>
            <td>{find_account_type_name(single_account)}</td>
            <td
              style={{
                width: '150px',
              }}
            >
              <Link className='btn btn-primary btn-small' to={`/companies/${company_id}/sub_accounts/${single_account._id}/account_statements`}>
                Acc. St.
              </Link>
            </td>
            <td>{single_account.notes}</td>
            <td>{render_edit_sub_acc_btn(single_account)}</td>
            <td>{render_delete_sub_acc_btn(single_account)}</td>
          </tr>
        </>
      );
    });

    return sub_accounts_rows;
  };

  const expand_sub_account = (event) => {
    if (event.target.closest('.plus-icon-svg')?.nodeName) {
      // We pressed plus icon
      event.target.closest('span').classList.add('d-none');
      event.target.closest('tr').querySelectorAll('span.minus-icon-svg')[0].classList.remove('d-none');
      let parentRowClass = event.target.closest('tr').className.split('acc-')[1];

      let children_elems = event.target.closest('tbody').childNodes;
      for (let i = 0; i < children_elems.length; i++) {
        let inner_row = children_elems[i];

        if (inner_row.className.includes(`sub-acc-row-${parentRowClass}`)) {
          inner_row.classList.remove('d-none');
        }
      }
      // event.target.closest('tr').nextSibling.classList.remove('d-none');
    } else if (event.target.closest('.minus-icon-svg')?.nodeName) {
      // We pressed minus icon
      event.target.closest('span').classList.add('d-none');
      event.target.closest('tr').querySelectorAll('span.plus-icon-svg')[0].classList.remove('d-none');

      let parentRowClass = event.target.closest('tr').className.split('acc-')[1];

      let children_elems = event.target.closest('tbody').childNodes;
      for (let i = 0; i < children_elems.length; i++) {
        let inner_row = children_elems[i];

        if (inner_row.className.includes(`sub-acc-row-${parentRowClass}`)) {
          inner_row.classList.add('d-none');
        }
      }
    }
  };

  const handleShow = (event) => {
    setEditUserNotes(event.target.dataset.id);
    setNotesModal(true);
  };

  const handleSubAccountShow = (event) => {
    setEditSubAccountNotes(event.target.dataset.id);
    setSubAccountNotesModal(true);
  };

  const handleSubAccountDeleteModal = (event) => {
    setDeleteSubAccount(event.target.dataset.id);
    setSubAccountDeleteModal(true);
  };

  const handleSubAccountClose = () => {
    setSubAccountNotesModal(false);
    setSubAccountDeleteModal(false);
  };

  const handleClose = () => {
    setNotesModal(false);
  };

  const handleNotesSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'put',
      url: `/companies/${company_id}/users/${edit_user_notes_id}`,
      data: formData,
    })
      .then((response) => {
        setNotesModal(false);
        get_all_accounts();
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const handleSubAccNotesSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'put',
      url: `/companies/${company_id}/sub_accounts/${edit_sub_account_notes_id}`,
      data: formData,
    })
      .then((response) => {
        setSubAccountNotesModal(false);
        get_all_accounts();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleSubAccDeleteSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'delete',
      url: `/companies/${company_id}/sub_accounts/${delete_sub_account_id}`,
      data: formData,
    })
      .then((response) => {
        setSubAccountDeleteModal(false);
        get_all_accounts();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const render_sub_acc_delete_modal = () => {
    let delete_modal = (
      <Modal show={show_sub_acc_delete_modal} onHide={handleSubAccountClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Sub Account</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubAccDeleteSubmit}>
          <Modal.Body>
            <div className='form-group'>
              <label>Are you sure you want to delete this Sub-Account ?</label>
              <input type='hidden' name='sub_account[is_deleted]' value={true} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='btn btn-secondary' onClick={handleSubAccountClose}>
              No
            </button>
            <button type='submit' className='btn btn-danger'>
              Yes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return delete_modal;
  };

  const isNonDefault = () => {
    let is_non_default = false;

    users.forEach((single_user) => {
      single_user.sub_accounts.forEach((single_account) => {
        if (single_account._id === edit_sub_account_notes_id && single_account.account_type.is_default === false) {
          is_non_default = true;
        }
      });
    });

    return is_non_default;
  };

  const render_name_field = () => {
    let name_field_html = <></>;

    if (isNonDefault()) {
      name_field_html = (
        <div className='form-group'>
          <label>Title</label>
          <input type='text' className='form-control' placeholder='Title' name='sub_account[account_username]' />
        </div>
      );
    }

    return name_field_html;
  };

  const render_sub_acc_notes_modal = () => {
    let notes_modal = (
      <Modal show={show_sub_acc_notes_modal} onHide={handleSubAccountClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubAccNotesSubmit}>
          <Modal.Body>
            {render_name_field()}
            <div className='form-group'>
              <label>Notes</label>
              <input type='text' className='form-control' placeholder='Notes' name='sub_account[notes]' />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='btn btn-secondary' onClick={handleSubAccountClose}>
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return notes_modal;
  };

  const render_notes_modal = () => {
    let notes_modal = (
      <Modal show={show_notes_modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Notes</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleNotesSubmit}>
          <Modal.Body>
            <div className='form-group'>
              <label>Notes</label>
              <input type='text' className='form-control' placeholder='Notes' name='user[notes]' />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='btn btn-secondary' onClick={handleClose}>
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return notes_modal;
  };

  const render_users = () => {
    let users_html = [];
    users.forEach((single_user, index) => {
      if (is_accessible_user(single_user)) {
        users_html.push(
          <>
            <tr className={`acc-${single_user._id}`}>
              <td
                style={{
                  width: '200px',
                }}
              >
                {single_user.account_pk}
              </td>
              <td
                style={{
                  width: '200px',
                  paddingLeft: '50px',
                }}
              >
                <span className='pr-2 plus-icon-svg' onClick={expand_sub_account}>
                  <AddCircleRoundedIcon color='primary' />
                </span>
                <span className='pr-2 minus-icon-svg d-none' onClick={expand_sub_account}>
                  <RemoveCircleRoundedIcon color='primary' />
                </span>
                <span>{single_user.username}</span>
              </td>
              <td
                style={{
                  width: '400px',
                  paddingLeft: '100px',
                }}
                className={parseFloat(new Intl.NumberFormat().format(single_user.balance.$numberDecimal)) > 0 ? 'plus-color' : 'neg-color'}
              >
                {new Intl.NumberFormat().format(parseInt(single_user.balance.$numberDecimal))}
              </td>
              <td></td>
              <td></td>
              <td>{single_user.notes}</td>
              <td>{render_edit_acc_btn(single_user)}</td>
              <td></td>
            </tr>
            {render_sub_accounts(single_user)}
          </>
        );
      }
    });

    users_html.push(
      <>
        <tr className='thead-dark'>
          <th
            style={{
              width: '200px',
            }}
          ></th>
          <th
            style={{
              width: '200px',
              paddingLeft: '50px',
            }}
          ></th>
          <th
            style={{
              width: '400px',
              paddingLeft: '100px',
            }}
          >
            {new Intl.NumberFormat().format(parseInt(get_users_total_balance()))}
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </>
    );
    return users_html;
  };

  const get_sub_acc_length = () => {
    instance({
      method: 'get',
      url: '/sub_accounts/length',
    })
      .then((response) => {
        setNewSubAccPK(response.data.sub_accounts_length + 1);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const get_users_length = () => {
    instance({
      method: 'get',
      url: '/users/length',
    })
      .then((response) => {
        setNewUserPK(response.data.users_length + 1);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleNewAccountClose = () => {
    setNewAccountModal(false);
  };

  const handleNewAccShow = (event) => {
    get_users_length();
    setNewAccountModal(true);
  };

  const handleNewSubAccShow = () => {
    get_sub_acc_length();
    setNewSubAccountModal(true);
  };

  const handleNewSubAccClose = () => {
    setNewSubAccountModal(false);
  };

  const handleColumnClick = (event) => {
    event.preventDefault();
    setSortColumn(event.target.textContent.toLowerCase());
    if (sort_order === '') {
      setSortOrder('asc');
    } else if (sort_order === 'asc') {
      setSortOrder('desc');
    } else if (sort_order === 'desc') {
      setSortOrder('asc');
    }
  };

  const handleNewAccSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: `/companies/${company_id}/users`,
      data: formData,
    })
      .then((response) => {
        get_all_accounts();
        handleNewAccountClose();
        setError(false);
      })
      .catch((error) => {
        let error_msg = error?.response?.data?.message?.message;
        if (error?.response?.data?.message?.message?.includes('to be unique')) {
          error_msg = 'Account already exists';
          setError(error_msg);
        }
        console.log(error);
      });
  };

  const handleNewSubAccSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: `/companies/${company_id}/sub_accounts`,
      data: formData,
    })
      .then((response) => {
        get_all_accounts();
        handleNewSubAccClose();
        setError(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const render_company_input = () => {
    let company_input = (
      <>
        <input type='hidden' name='user[company_id]' value={company_id} />
      </>
    );

    return company_input;
  };

  const render_error = () => {
    let error_html = <></>;
    if (error_message != false) {
      error_html = (
        <div className='alert alert-danger' role='alert'>
          {error_message}
        </div>
      );
    }

    return error_html;
  };

  const render_new_account_modal = () => {
    let new_account_modal_html = (
      <Modal show={show_new_account_modal} onHide={handleNewAccountClose}>
        <Modal.Header closeButton>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-4 pl-0'>A/C ID: A{new_user_pk}</div>
              <div className='col-8'>
                <strong>Add New Account</strong>
              </div>
            </div>
          </div>
        </Modal.Header>
        <form onSubmit={handleNewAccSubmit}>
          <Modal.Body>
            {render_error()}
            <div className='form-group'>
              <label>Title</label>
              <input type='text' className='form-control' placeholder='Title' name='user[username]' />
            </div>
            <div className='form-group'>
              <label>Notes</label>
              <input type='text' className='form-control' placeholder='Notes' name='user[notes]' />
            </div>
            {render_company_input()}
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='btn btn-secondary' onClick={handleNewAccountClose}>
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return new_account_modal_html;
  };

  const render_new_sub_account_modal = () => {
    const account_options = get_account_types_json();
    const user_options = get_users_json();

    let new_account_modal_html = (
      <Modal show={show_new_sub_account_modal} onHide={handleNewSubAccClose}>
        <Modal.Header closeButton>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-4 pl-0'>S. A/C: SA{new_sub_acc_pk}</div>
              <div className='col-8'>
                <strong>Add New Sub-Account</strong>
              </div>
            </div>
          </div>
        </Modal.Header>
        <form onSubmit={handleNewSubAccSubmit}>
          <Modal.Body>
            {render_error()}
            <div className='form-group'>
              <label>Title</label>
              <input type='text' className='form-control' placeholder='Title' name='sub_account[account_username]' />
            </div>
            <div className='form-group'>
              <label>Website</label>
              <Select options={account_options} name={`sub_account[account_type_id]`} />
            </div>
            <div className='form-group'>
              <label>Parent Account</label>
              <Select options={user_options} name={`sub_account[user_id]`} />
            </div>
            <div className='form-group'>
              <label>Notes</label>
              <input type='text' className='form-control' placeholder='Notes' name='sub_account[notes]' />
            </div>
            {render_company_input()}
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='btn btn-secondary' onClick={handleNewSubAccClose}>
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );

    return new_account_modal_html;
  };

  const handleFilterClick = () => {
    setFiltersShow(!show_filters);
  };

  const render_filters = () => {
    let filters_html = (
      <div className={`filter_container ${show_filters ? '' : 'd-none'}`}>
        <label className='mr-2 mb-0'>
          <input
            className='mr-1'
            type='radio'
            name='user_search_filter'
            value='account_id'
            onChange={(event) => {
              setAccountFilter(event.target.value);
            }}
          />
          ID
        </label>
        <label className='mr-2 mb-0'>
          <input
            className='mr-1'
            type='radio'
            name='user_search_filter'
            value='account_title'
            onChange={(event) => {
              setAccountFilter(event.target.value);
            }}
          />
          Title
        </label>
        <label className='mr-2 mb-0'>
          <input
            className='mr-1'
            type='radio'
            name='user_search_filter'
            value='account_status'
            onChange={(event) => {
              setAccountFilter(event.target.value);
            }}
          />
          Status
        </label>
      </div>
    );

    return filters_html;
  };

  const set_api_url = () => {
    let url = `/companies/${company_id}/users?search_value=${search_value}&`;

    if (selected_filter === 'account_id') {
      url += 'account_id=true';
    } else if (selected_filter === 'account_title') {
      url += 'account_title=true';
    } else if (selected_filter === 'account_status') {
      url += 'account_status=true';
    }

    return url;
  };

  const handleFilterSubmit = (event) => {
    event.preventDefault();

    if (search_value === '') {
      return;
    }

    let api_url = set_api_url();

    instance({
      method: 'get',
      url: api_url,
    })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const render_edit_acc_btn = (single_user) => {
    let edit_acc_btn = <></>;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      edit_acc_btn = (
        <button className='btn btn-sm btn-primary ml-1' onClick={handleShow} data-id={single_user._id}>
          Edit
        </button>
      );
    } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length && permissions.accounts.edit) {
      edit_acc_btn = (
        <button className='btn btn-sm btn-primary ml-1' onClick={handleShow} data-id={single_user._id}>
          Edit
        </button>
      );
    }

    return edit_acc_btn;
  };

  const render_edit_sub_acc_btn = (single_account) => {
    let edit_sub_acc_btn = <></>;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      edit_sub_acc_btn = (
        <button className='btn btn-sm btn-primary ml-1' onClick={handleSubAccountShow} data-id={single_account._id}>
          Edit
        </button>
      );
    } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length && permissions.accounts.edit) {
      edit_sub_acc_btn = (
        <button className='btn btn-sm btn-primary ml-1' onClick={handleSubAccountShow} data-id={single_account._id}>
          Edit
        </button>
      );
    }

    return edit_sub_acc_btn;
  };

  const render_delete_sub_acc_btn = (single_account) => {
    let delete_sub_acc_btn = <></>;

    if (single_account.account_type.is_default) {
      // Do not show Delete button, if AccountType is_default=true
    } else {
      if (localStorage.getItem('eFinanceUser') === 'Admin') {
        delete_sub_acc_btn = (
          <button className='btn btn-sm btn-danger ml-1' onClick={handleSubAccountDeleteModal} data-id={single_account._id}>
            Delete
          </button>
        );
      } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length && permissions.accounts.delete) {
        delete_sub_acc_btn = (
          <button className='btn btn-sm btn-danger ml-1' onClick={handleSubAccountDeleteModal} data-id={single_account._id}>
            Delete
          </button>
        );
      }
    }

    return delete_sub_acc_btn;
  };

  const render_new_acc_btn = () => {
    let new_acc_btn = <></>;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      new_acc_btn = (
        <button type='button' className='btn btn-primary' onClick={handleNewAccShow}>
          Add New A/C
        </button>
      );
    } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length && permissions.accounts.add) {
      new_acc_btn = (
        <button type='button' className='btn btn-primary' onClick={handleNewAccShow}>
          Add New A/C
        </button>
      );
    }

    return new_acc_btn;
  };

  const render_new_sub_acc_btn = () => {
    let new_sub_acc_btn = <></>;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      new_sub_acc_btn = (
        <button type='button' className='btn btn-primary ml-3' onClick={handleNewSubAccShow}>
          Add New S. A/C
        </button>
      );
    } else if (localStorage.getItem('eFinanceUser') === 'Company' && Object.keys(permissions).length && permissions.accounts.add) {
      new_sub_acc_btn = (
        <button type='button' className='btn btn-primary ml-3' onClick={handleNewSubAccShow}>
          Add New S. A/C
        </button>
      );
    }

    return new_sub_acc_btn;
  };

  const is_accessible_user = (curr_user) => {
    let is_accessible = false;

    if (Object.keys(permissions).length) {
      permissions.viewable_accounts.forEach((single_acc) => {
        if (single_acc == curr_user._id) {
          is_accessible = true;
        }
      });
    }

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      is_accessible = true;
    }

    return is_accessible;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      {render_new_account_modal()}
      {render_new_sub_account_modal()}
      {render_notes_modal()}
      {render_sub_acc_notes_modal()}
      {render_sub_acc_delete_modal()}
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-4'>
            {render_new_acc_btn()}
            {render_new_sub_acc_btn()}
          </div>
          <div className='col-4 text-center'>
            <h3>Accounts</h3>
          </div>
          <div className='col-3 offset-1'>
            <form onSubmit={handleFilterSubmit}>
              <div>
                <input
                  type='text'
                  name='search_value'
                  onChange={(event) => {
                    setSearchVal(event.target.value);
                  }}
                />
                <FilterListIcon onClick={handleFilterClick} style={{ cursor: 'pointer' }} />
              </div>
              {render_filters()}
            </form>
            <button type='button' className='btn btn-primary btn-sm mt-1' onClick={get_all_accounts}>
              Reset
            </button>
          </div>
        </div>

        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th
                scope='col'
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleColumnClick}
              >
                <span>⇅</span>
                <span>#</span>
              </th>
              <th
                scope='col'
                style={{
                  cursor: 'pointer',
                }}
                className='text-center'
                onClick={handleColumnClick}
              >
                <span>⇅</span>
                <span>Title</span>
              </th>
              <th
                scope='col'
                style={{
                  paddingLeft: '100px',
                  cursor: 'pointer',
                }}
                onClick={handleColumnClick}
              >
                <span>⇅</span>
                <span>Balance</span>
              </th>
              <th></th>
              <th></th>
              <th scope='col'>Notes</th>
              <th scope='col'></th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>{render_users()}</tbody>
        </table>
      </div>
    </>
  );
};

export default Accounts;
