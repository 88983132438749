import { FETCH_COMPANIES } from './types';
import instance from '../../config/axios';

export const fetchCompanies = () => async (dispatch) => {
  instance({
    method: 'get',
    url: 'companies',
  })
    .then((response) => {
      dispatch({
        type: FETCH_COMPANIES,
        payload: response.data.companies,
      });
    })
    .catch((error) => {
      console.log(error.response.data.message);
    });
};
