import React, { useState, useEffect } from 'react';
import instance from '../../config/axios';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import NavBar from '../../components/NavBar';

const NewUser = () => {
  let history = useHistory();
  let { company_id } = useParams();
  const [account_types, setAccountTypes] = useState([]);
  const [sub_accounts, setSubAccounts] = useState([]);

  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/account_types`,
    })
      .then((response) => {
        setAccountTypes(response.data.account_types);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);

    instance({
      method: 'post',
      url: `/companies/${company_id}/users`,
      data: formData,
    })
      .then((response) => {
        history.push(`/companies/${company_id}/users`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const render_company_input = () => {
    let company_input = (
      <>
        <input type='hidden' name='user[company_id]' value={company_id} />
      </>
    );

    return company_input;
  };

  const get_account_types_json = () => {
    let account_types_json = [];
    account_types.forEach((single_account) => {
      account_types_json.push({
        label: single_account.name,
        value: single_account._id,
        key: single_account._id,
      });
    });

    return account_types_json;
  };

  const add_new_sub_account = () => {
    let newKey = sub_accounts.length;
    setSubAccounts([
      ...sub_accounts,
      {
        key: newKey,
        account_type_id: null,
        account_username: null,
      },
    ]);
  };

  const render_sub_accounts_container = () => {
    let sub_accounts_container_html = (
      <>
        <div className='sub-accounts-container'>
          <div className='sub-account-header mb-4'>
            <h3 className='d-inline-block'> Add Sub Accounts </h3>
            <button
              type='button'
              className='btn btn-primary add-more-sub-account-btn'
              onClick={add_new_sub_account}
            >
              Add More
            </button>
          </div>
          {render_all_sub_accounts()}
        </div>
      </>
    );

    return sub_accounts_container_html;
  };

  const render_all_sub_accounts = () => {
    let sub_accounts_html = [];

    sub_accounts.forEach((single_sub_account) => {
      sub_accounts_html.push(render_single_sub_account(single_sub_account));
    });

    return sub_accounts_html;
  };

  const removeSubAccount = (event) => {
    let id = event.target.id.split('-')[1];
    setSubAccounts(sub_accounts.filter((item) => item.key !== parseInt(id)));
  };

  const render_single_sub_account = (single_sub_account) => {
    const options = get_account_types_json();

    let single_sub_account_html = (
      <>
        <div className='single-sub-account mb-3'>
          <Select
            options={options}
            className='col-4 pl-0 d-inline-block'
            name={`user[sub_account][${single_sub_account.key}][account_type_id]`}
            defaultValue={single_sub_account.account_type_id}
          />
          <input
            type='text'
            className='form-control col-4 d-inline-block'
            name={`user[sub_account][${single_sub_account.key}][account_username]`}
            defaultValue={single_sub_account.account_username}
          />
          <button
            type='button'
            className='btn btn-danger ml-4'
            id={`remove-${single_sub_account.key}`}
            onClick={removeSubAccount}
          >
            Remove
          </button>
        </div>
      </>
    );

    return single_sub_account_html;
  };

  const new_user_form = () => {
    let user_form = (
      <div className='container'>
        <form className='form mt-4' onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              name='user[username]'
              placeholder='Username'
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              name='user[phone]'
              placeholder='Phone Number'
            />
          </div>
          {render_company_input()}
          {render_sub_accounts_container()}
          <button className='btn btn-primary mt-4'>Submit</button>
        </form>
      </div>
    );

    return user_form;
  };

  return (
    <>
      <NavBar show_navs={true} /> {new_user_form()}
    </>
  );
};

export default NewUser;
