import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import NavBar from '../../components/NavBar';
import instance from '../../config/axios';

const Currencies = (props) => {
  const [currencies, setCurrencies] = useState([]);

  // Fetch all currencies on page load
  useEffect(() => {
    instance({
      method: 'get',
      url: `/currencies`,
    })
      .then((response) => {
        setCurrencies(response.data.currencies);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const render_currencies = () => {
    let currencies_html = [];

    currencies.forEach((single_currency, index) => {
      currencies_html.push(
        <tr>
          <td>{index + 1}</td>
          <td>{single_currency.currency_code}</td>
        </tr>
      );
    });
    return currencies_html;
  };

  return (
    <>
      <NavBar show_navs={false} />
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-12 d-flex justify-content-between'>
            <h3>Currencies</h3>
            <Link to='/currencies/new' className='btn btn-primary float-right'>
              Add Currency
            </Link>
          </div>
        </div>

        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Name</th>
            </tr>
          </thead>
          <tbody>{render_currencies()}</tbody>
        </table>
      </div>
    </>
  );
};

export default Currencies;
