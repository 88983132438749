import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import instance from '../../config/axios';
import NavBar from '../../components/NavBar';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const ShowAccountType = (props) => {
  let { company_id, id } = useParams();
  let history = useHistory();

  const [sub_accounts, setSubAccounts] = useState([]);
  const [account_type, setAccountType] = useState({});
  const [permissions, setPermissions] = useState({});

  // Fetch account type data
  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/account_types/${id}`,
    })
      .then((response) => {
        setAccountType(response.data.account_type);
        setSubAccounts(response.data.sub_accounts);
        setPermissions(response.data.permissions);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
      });
  }, []);

  const render_single_row = (single_row) => {
    let take = '';
    let give = '';

    let balance = parseInt(single_row.balance.$numberDecimal);

    if (balance < 0) {
      take = balance;
    } else {
      give = balance;
    }

    let sub_account_html = (
      <tr>
        <td>{single_row.account_username}</td>
        <td>{take}</td>
        <td>{give}</td>
      </tr>
    );

    return sub_account_html;
  };

  const render_sheet_table = () => {
    let sub_accounts_html = [];

    if (sub_accounts.length) {
      sub_accounts_html = (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Username</th>
              <th scope='col'>Take</th>
              <th scope='col'>Give</th>
            </tr>
          </thead>
          <tbody>{render_all_accounts()}</tbody>
        </table>
      );
    }

    return sub_accounts_html;
  };

  const render_all_accounts = () => {
    let all_rows_html = [];
    sub_accounts.forEach((single_row) => {
      all_rows_html.push(render_single_row(single_row));
    });

    return all_rows_html;
  };

  const render_header = () => {
    let header_html = '';

    header_html = (
      <div>
        <h3>{account_type?.name}</h3>
      </div>
    );

    return header_html;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />

      <div className='container mt-4'>
        {render_header()}
        {render_sheet_table()}
      </div>
    </>
  );
};

export default ShowAccountType;
