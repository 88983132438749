import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import NavBar from '../../components/NavBar';
import * as Action from '../../store/actions';

const Companies = (props) => {
  const dispatch = useDispatch();
  const companies = useSelector(({ companies }) => companies.list);

  // Fetch all companies on page load
  useEffect(() => {
    dispatch(Action.fetchCompanies());
  }, []);

  const render_companies = () => {
    let companies_html = [];

    companies.forEach((single_company, index) => {
      companies_html.push(
        <>
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{single_company.name}</td>
            <td>{single_company.is_active ? 'True' : 'False'}</td>
            <td>
              <Link to={`/companies/${single_company._id}`} className='btn btn-success mr-2'>
                Show
              </Link>
            </td>
          </tr>
        </>
      );
    });
    return companies_html;
  };

  return (
    <>
      <NavBar show_navs={false} />
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-12 d-flex justify-content-between'>
            <h3>Companies</h3>
            <Link to='/companies/new' className='btn btn-primary float-right'>
              Add Company
            </Link>
          </div>
        </div>

        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Name</th>
              <th scope='col'>Active</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>{render_companies()}</tbody>
        </table>
      </div>
    </>
  );
};

export default Companies;
