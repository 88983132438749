import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SignIn from './components/SignIn';
import { Switch } from 'react-router-dom';

// Account Types CRUD
import AccountTypes from './components/account_types/list';
import NewAccountType from './components/account_types/new';
import ShowAccountType from './components/account_types/show';

// Companies CRUD
import Companies from './components/companies/list';
import NewCompany from './components/companies/new';
import ShowCompany from './components/companies/show';

// Users CRUD
import Accounts from './components/users/accounts';
import CompanyUser from './components/users/company_user';
import NewUser from './components/users/new';
import ShowUser from './components/users/show';

// Banks CRUD
import Banks from './components/bank_types/list';
import NewBank from './components/bank_types/new';

// Transactions CRUD
import Transactions from './components/transactions/list';
import NewTransaction from './components/transactions/new';

// Import Data Component
import ImportData from './components/import_data/new';

// Ledger Component
import ShowLedger from './components/ledgers/show';

// Ledger Component
import AccountStatementsList from './components/account_statements/list';

// Currencies CRUD
import Currencies from './components/currencies/list';
import NewCurrency from './components/currencies/new';

// Accessibility
import Accessibility from './components/accessibility/accessibility';
import AccountsPermissions from './components/accessibility/accounts';

// Welcome
import Welcome from './components/welcome';

import ProtectedRoute from './components/ProtectedRoute';
import HomeRoute from './components/HomeRoute';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className='App'>
      <>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <HomeRoute exact path='/' component={SignIn} />

          {/* Account Types Routes */}
          <ProtectedRoute
            exact
            path='/companies/:company_id/account_types'
            component={AccountTypes}
          />
          <ProtectedRoute
            exact
            path='/companies/:company_id/account_types/new'
            component={NewAccountType}
          />
          <ProtectedRoute
            exact
            path='/companies/:company_id/account_types/:id'
            component={ShowAccountType}
          />

          {/* Company Routes */}
          <ProtectedRoute exact path='/companies' component={Companies} />
          <ProtectedRoute exact path='/companies/new' component={NewCompany} />
          <ProtectedRoute
            exact
            path='/companies/:company_id'
            component={ShowCompany}
          />

          {/* User Routes */}
          <ProtectedRoute
            exact
            path='/companies/:company_id/accounts'
            component={Accounts}
          />
          <ProtectedRoute
            exact
            path='/companies/:company_id/users'
            component={CompanyUser}
          />
          <ProtectedRoute
            path='/companies/:company_id/users/new'
            component={NewUser}
          />
          <ProtectedRoute
            exact
            path='/companies/:company_id/users/:id'
            component={ShowUser}
          />

          {/* Bank Routes */}
          <ProtectedRoute exact path='/banks' component={Banks} />
          <ProtectedRoute exact path='/banks/new' component={NewBank} />

          {/* Transactions */}
          <ProtectedRoute
            exact
            path='/companies/:company_id/transactions'
            component={Transactions}
          />
          <ProtectedRoute
            path='/companies/:company_id/transactions/new'
            component={NewTransaction}
          />

          {/* Import xlxs */}
          <ProtectedRoute
            exact
            path='/companies/:company_id/import'
            component={ImportData}
          />

          {/* Ledgers */}
          <ProtectedRoute
            path='/companies/:company_id/users/:user_id/ledger'
            component={ShowLedger}
          />

          {/* Ledgers */}
          <ProtectedRoute
            path='/companies/:company_id/sub_accounts/:sub_account_id/account_statements'
            component={AccountStatementsList}
          />

          {/* Currencies */}
          <ProtectedRoute path='/currencies' exact component={Currencies} />
          <ProtectedRoute
            path='/currencies/new'
            exact
            component={NewCurrency}
          />

          {/* Accessibility */}
          <ProtectedRoute
            path='/companies/:company_id/users/:user_id/accessibility'
            exact
            component={Accessibility}
          />
          <ProtectedRoute
            path='/companies/:company_id/users/:user_id/accounts'
            exact
            component={AccountsPermissions}
          />

          {/* Welcome */}
          <ProtectedRoute
            path='/companies/:company_id/Welcome'
            exact
            component={Welcome}
          />
        </Switch>
      </>
    </div>
  );
}

export default App;
