import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import instance from '../../config/axios';
import { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import { toast } from 'react-toastify';
import moment from 'moment';

const AccountTypes = (props) => {
  const [account_types, setAccountTypes] = useState([]);
  const [permissions, setPermissions] = useState({});
  let { company_id } = useParams();

  let history = useHistory();

  // Fetch all account types on page load
  useEffect(() => {
    instance({
      method: 'get',
      url: `/companies/${company_id}/account_types`,
    })
      .then((response) => {
        setAccountTypes(response.data.account_types);
        setPermissions(response.data.permissions);
      })
      .catch((error) => {
        console.log(error.response.data.message);

        if (error.response.status === 403) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          history.push('/');
        }
      });
  }, []);

  const render_account_types = () => {
    let account_types_html = [];

    account_types.forEach((single_account_type, index) => {
      account_types_html.push(
        <>
          <tr>
            <td>{index + 1}</td>
            <td>{single_account_type.name}</td>
            <td>
              {moment(new Date(single_account_type.updated_at)).format(
                'MM/DD/YYYY HH:mm'
              )}
            </td>
            <td>{single_account_type.is_active ? 'True' : 'False'}</td>
            <td>{single_account_type.is_default ? 'True' : 'False'}</td>
            <td>
              <Link
                to={`/companies/${company_id}/account_types/${single_account_type._id}`}
                className='btn btn-primary'
              >
                Show
              </Link>
            </td>
          </tr>
        </>
      );
    });
    return account_types_html;
  };

  const render_add_btn = () => {
    let add_btn_html = <></>;

    if (localStorage.getItem('eFinanceUser') === 'Admin') {
      add_btn_html = (
        <Link
          to={`/companies/${company_id}/account_types/new`}
          className='btn btn-primary float-right'
        >
          Add Account Type
        </Link>
      );
    } else if (
      localStorage.getItem('eFinanceUser') === 'Company' &&
      Object.keys(permissions).length > 0 &&
      permissions.account_types.add === true
    ) {
      add_btn_html = (
        <Link
          to={`/companies/${company_id}/account_types/new`}
          className='btn btn-primary float-right'
        >
          Add Account Type
        </Link>
      );
    }

    return add_btn_html;
  };

  return (
    <>
      <NavBar show_navs={true} permissions={permissions} />
      <div className='container'>
        <div className='row mt-4 mb-4'>
          <div className='col-12 d-flex justify-content-between'>
            <h3>Account Types</h3>
            {render_add_btn()}
          </div>
        </div>

        <table className='table shadow'>
          <thead className='thead-dark'>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Name</th>
              <th scope='col'>Last Updated At</th>
              <th scope='col'>Active</th>
              <th scope='col'>Default</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>{render_account_types()}</tbody>
        </table>
      </div>
    </>
  );
};

export default AccountTypes;
